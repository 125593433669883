.Footer {
    background-color: rgb(255,244,245);
    display: flex;
    flex-direction: column ;
    justify-content: center;
    align-items: center;
    width: 100vw;

    p {
        color: rgb(247, 104, 128);
        margin-top: 1rem;
    }

    .footer-links {
        display: flex;

        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 1.5rem;
            color: rgb(235, 154, 167);
            margin: 0rem;
            text-decoration: none;

            h5 {
                padding: .5rem;
                font-size: 1.2rem;
                text-align: center;
                
            }
        
            &:hover {
                color:  rgb(144, 199, 181) ;
            }
        }
    }
    
}

@media screen and (max-width: 786px) {
    .Footer {
    
        p {
            color: rgb(247, 104, 128);
            margin-top: .8rem;
        }
        .footer-links {
            a {
                font-size: 1rem;
                color: rgb(235, 154, 167);
                margin: 0rem;
                text-decoration: none;
    
                h5 {
                    padding: .5rem;
                    font-size: 1rem;
                    text-align: center; 
                }
            }
        }
    }
}


