.ProductItem {
    line-height: 2rem;
    padding: .6rem 0rem;

    h2 {
        padding-bottom: 1.5rem;
    }

    .container {
        padding: 2rem ;
    }

    .plant-img {
        height: 60vh;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }


}

@media screen and (max-width: 767px) {
    .ProductItem {

        h2 {
            padding: 1.5rem 0;
        }
        .container {
            padding: 2rem ;
        }
    
        .plant-img {
          background-size: cover;
        }

        .btn-addtocart-container {
            display: flex;
            justify-content: flex-end;
    
        }
    
    }

}