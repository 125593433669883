.About {
    .aboutUsImg {
        width: 100%;
    }

    .container {
        padding: 5rem 5rem ;
    }

    h2 {
        padding-bottom:  1rem;
        color: rgb(116, 197, 170);
    }

}