.Cart {

    padding: 3rem 1rem;

    h1 {
        color: rgb(116, 197, 170);
    }

    .emptyCart {
        font-size: 2rem;
        margin: 6rem;
    }




    .cartItemsTable {

        margin-top: 3rem;
        box-shadow: rgba(39, 90, 19, 0.35) 0px 5px 15px;
        border-radius: 0.25rem;

        .left-item-container {
            display: flex;
            // justify-content: center;
            align-items: center;



        }

        .right-item-container {
            display: flex;
            justify-content: space-around;
            align-items: center;



        }

        td {
            div {
                // margin-top: 1.5rem;
                padding: 1rem
            }
        }


        .removeBtn {
            border: none;
            background-color: white;
            padding-left: 0.5rem;

            &:hover {
                color: rgb(155, 22, 22);
            }
        }

        .product-price {
            width: 4.5rem;
        }

        .quantityContainer {
            div {

                display: inline;
            }
            .quantity {
                font-weight: bold;
            }

            .count {
                margin-top: 0;
                color: green;
                background-color: white;
                font-size: 1.3rem;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                height: 0.1rem;
                cursor: pointer;
            }
        }





        .subtotal-item {
            width: 5.5rem;
        }


    }

    .shippingDetails {
        box-shadow: rgba(39, 90, 19, 0.35) 0px 5px 15px;
        border-radius: 0.25rem;
        padding: 1em;
        margin-bottom: 1em;



    }

    .submit-button {
        margin-top: 1rem;
        margin-right: 1rem;
    }

    .summary {


        td {
            text-align: right;
            padding-right: 1rem;
        }
    }

    .discountContainer {
        display: flex;

        .inputDiscount {
            width: 11rem;
            height: 2.4rem;
            padding-left: 0.5rem;
        }

        .discountBtn {

            margin-left: 0.5rem
        }
    }
}

.CartOverlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;

    img {
        width: 100px;
        margin: 1rem;
    }
}