.Blog {
    display: flex;
    justify-content: center;

    h1 {
        padding: 3rem;
        font-size: 25vw;
        color: rgb(116, 197, 170);
        top: 20px;
        left: 2px;
        max-width: 100%;
        background-size: cover;
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
    }
}