.Main {
    overflow: hidden;
    min-height: 100vh;
    .Hero {
        background-color: rgba(114, 187, 165, 0.082);
        position: relative;
        .banner {
            h1 {
                position: absolute;
                top: 73%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: rgb(248, 183, 183);
                text-align: center;
                font-size: 3rem;
                
            }
            a {
                padding: 0;
                .btn {
                    margin-top: 2rem;
                    position: absolute;
                    top: 83%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    padding: .8rem 2rem;
                    
                    &:hover {
                        color: rgb(14, 75, 14);
                    }
    
                    .icon-arrow {
                        margin-left: .5rem;
                    }
                }
            }
            
        }
    }

    .BlogCarousel {
        margin: 4rem 0;
        h1 {
            text-align: center;
            margin-top: 2rem;
            color: rgb(116, 197, 170);
        }
        
        .flex-carousel-container {

            margin-top: 2rem;
            width: 100%;
            display: flex;
            justify-content: center;
            
        
            .carousel {
                width: 60%;
                border-radius: 5%;
                color: white;
                .carousel-inner {
                    border-radius: 5%;

                    .carousel-caption {
                        h5 {
                            font-size: 1.5rem;
                        }
                        color: white;
                        background-color: rgba(102, 202, 169, 0.411);;
                    }
                }

                img {
                    border-radius: 5%;
                }
            
            }
        }
    }

    .MainContent {
        background-color: rgb(255,244,245);
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
 
        .mainRow-1 {
            padding: 2rem;
            
            .mainCol-1 {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 1rem;
                text-transform: uppercase;

                h1 {
                    text-align: center;
                }

                p {
                    margin: 1.3rem;
                }

                .iconShopWithUs {
                    margin-right: 1rem;
                    font-size: 1.2rem;
                }
            }

            .mainCol-2{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .tropicalWallpaperImg {
                    width: 100%;
                }
            }  
        }    
    }
}



/////////////////////////////////////// Media Queries

@media screen and (max-width: 1224px) {

    // CAROUSEL BLOG
    .Main {
        .BlogCarousel {
            margin: 4rem 2rem;
            .flex-carousel-container {
                display: block;
                .carousel {
                    width: 100%;
                }
             }
        }
    } 
}

@media screen and (max-width: 768px) {

    .Main {
        .Hero {
            .banner {
                h1 {
                    top: 60%;
                    left: 50%;
                }
                
                a {
                    .btn {
                        margin-top: 2rem;
                        top: 82%;
                        left: 50%;
                        padding: .5rem 1rem;
                    }
                }
            }
        }
    
        // MAIN CONTENT - WHY SHOP WITH US
        .MainContent {
            .mainRow-1 {
                margin: 15%;
                .mainCol-1 {
                    p {
                        margin: 1rem;
                        text-align: center;
                    }
                }
            }    
        }
    }
}


@media screen and (max-width: 480px) {
    // Hero
    .Main {
        .Hero {
            .banner {
                h1 {
                    top: 55%;
                    left: 50%;
                }
                
                a {
                    .btn {
                        margin-top: 2rem;
                        top: 82%;
                        left: 50%;
                        padding: .5rem 1rem;
                    }
                }
            }
        }
    }

    // carousel - blog
    .Main {
        .BlogCarousel {
            margin: 5rem 1rem;
            .flex-carousel-container {
                .carousel {
                    .carousel-inner {
                        .carousel-caption {
                            h5 {
                                font-size: .9rem;
                            }
                            p {
                                font-size: .8rem;
                            }
                        }
                    }
                }
            }
        }
    }
}